<template>
  <section>
    <div class="pageBradcrumbs">
      <div class="containerLarge">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb small">
            <li class="breadcrumb-item">
               <b-button @click="toGlobal('/marine', 'marine')">Home</b-button>
            </li>
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/size-your-system', 'marine')">Size your system</b-button>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="banner">
      <div
        class="bannerImage"
        :style="{
          'background-image':
            'url(' + getCover(element.cover) + ')',   
        }" 
      >
        <div class="containerLarge">
          <div class="pageTitle-banner text-center">
            <div class="pageTitle-content">
              <p class="lead mt-4 textTurquoise"><strong>You need</strong></p>
              <h1 class="mt-4">{{element.name}}</h1>
              <div class="divider"></div>
              <p class="lead">
               {{trimSize}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="caterory mt-5 pt-lg-3">
      <div class="container">
        <div class="row d-flex flex-row">
          <div class="col-md-6 align-self-center">
            <div class="lead" v-html="element.abstract"></div>
          </div>
          <div class="col-md-6 align-self-center text-center">
            <img :src="getImage(element.imgproducts)" alt="">                   
          </div>
        </div>
        <div class="row">
          <div class="col-md-8 offset-md-2 text-center mt-5 mb-5">
            <em>Result as an indication. <br>
            For a detailed offer or further questions please 
            <a class="linkClick" @click="toContacts"><u>Contact us</u></a>.</em>
          </div>
        </div>
      </div>
      <div class="accordion mt-4 mt-lg-5" role="tablist">
        <!-- accordion -->
        <b-card v-if="element.documents? element.documents.length > 0 : false" no-body class="cardAccordion my-1">
          <b-card-header header-tag="header" class="card-header" role="tab">
            <div class="container px-0">
                <h2 class="h4 mb-0">
                    <a v-b-toggle.accordion-4 class="text-decoration-none d-flex flex-row justify-content-between">
                       <span>Documents</span>
                       <b-icon-chevron-right></b-icon-chevron-right>
                    </a>
                </h2>
            </div>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="container"> 
                <ul class="listDocuments list-unstyled">
                  <li v-for="doc in orderedDocs(element.documents)" :key="doc.name">
                      <a v-if="!doc.archive" class="py-3" @click="show(doc)">
                          <div class="float-left pr-3">
                              <b-icon-file-earmark-text-fill font-scale="2"></b-icon-file-earmark-text-fill>
                          </div>
                          <div class="listDocuments-info">
                              {{doc.name}}
                          </div>
                      </a>
                  </li>
                </ul>
 
                <b-modal v-model="showModal" id="modal-1" title="Documents Downloads" hide-footer>
                  <form-download :name="name" :file="file" :link="link" :type="type" :size="element.size" :parent="element.name" :categoryId="getCategoryId" :elementId="getProductId" @close="close"></form-download>
                </b-modal>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </section>
</template>

<script>
import FormDownload from '../components/FormDownload.vue';

export default {
  name: 'Product',

  components: {
    FormDownload
  },

  data: () => ({
    type: 'prodotto',
    name: '',
    file: '',
    link: false,
    showModal: false,
    element: {}
  }),

  mounted(){
    this.getProduct();
  },

  computed: {
    trimSize(){
      let outStr = '';
      if (this.element.size.startsWith('P ') || this.element.size.startsWith('S ')) outStr = this.element.size.replace(/\s+/g, '');
      else outStr = this.element.size;

      return outStr;
    },

    getCurrentPath(){
      return this.$store.getters.getCurrentPath;
    },

    getCurrentGlobal(){
      return this.$store.getters.getCurrentGlobal;
    },

    getCategoryId(){
      return this.$store.getters.getCategoryId; //this.$route.params.category_id;
    },

    getProductId(){
      /* eslint-disable no-underscore-dangle */
      return this.element._id;
    } 
  },
  
  methods: {

    orderedDocs(list){
      return list.slice().sort(this.compare);
    },
  
    compare(a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    },

    toGlobal(str, current){
      if (str !== this.getCurrentPath){
        this.$store.commit('setCurrentGlobal', current);
        this.$store.commit('setCurrentPath', str);
        this.$router.push(str);
      }      
    },

    toContacts(){
      console.log('arriva');
      this.$store.commit('setProductContacts', JSON.stringify(this.element));
      this.$router.push('/marine-contacts');
    },

    close(){
      this.showModal = false;
    },

    show(doc){
      
      this.name = doc.name;
      this.file = doc.file;
      this.link = doc.link ? doc.link : false;
      console.log('doc', doc, this.name, this.file);
      this.showModal = true;
    },

    getCover(img) {
      /* eslint-disable global-require */
      const tmp = require('../assets/img/banner/slide-01.jpg');
      return img ? process.env.VUE_APP_CLOUDINARY_BASE + img : tmp;
    },

    getProduct(){
      this.$http.get('/api/sito/prodotto/get-by-id/?', { params: { id: this.$route.params.id } })
        .then((result) => {
          console.log('result prod', result);
          this.element = result.data[0];
        })
        .catch((err) => console.log('Problem on db: getProduct', err));
    },

    getImage(imgproducts) {
      const bucket = process.env.VUE_APP_CLOUDINARY_BUCKET;
      return imgproducts
        ? process.env.VUE_APP_CLOUDINARY_BASE + imgproducts            
        : 'https://res.cloudinary.com/plurimedia/image/upload/v1495121472/plurimedia/placeholder.png';
    },
  },
};
</script>

<style>
  .modal-title {
      font-weight: 700;
  }
</style>
